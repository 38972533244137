// 常购清单页
<template>
	<div class="sld_regular_list">
		<!-- <MemberTitle :memberTitle="L['常购清单']"></MemberTitle> -->
		<div class="container">
			<h3>{{ L['常购清单'] }}</h3>
			<div class="box2 flex_row_between_center">
				<div class="flex-row">
					<span class="word23" :class="{ active: tabIndex == 1 }" @click="changeTab(1)">近三个月常购</span>
					<span class="word23" :class="{ active: tabIndex == 2 }" @click="changeTab(2)">近半年常购</span>
					<span class="word23" :class="{ active: tabIndex == 3 }" @click="changeTab(3)">今年常购</span>
				</div>
				<div class="layer13 flex-row">
					<i class="iconfont iconsousuo- label8" @click="getList"></i>
					<el-input maxlength="30" v-model="selectValue" @clear="resetList" clearable
						:placeholder="memberType == 1 ? '产品名称/订货编码' : '产品名称/订货编码/物料编码'" />
					<div class="search13" @click="searchList">搜索</div>
				</div>
			</div>

			<template v-if="frequentList.length > 0">
				<!-- 顶部标题 start-->
				<div class="cart_title flex_row_start_center">
					<div class="cart_title_pre flex_row_start_center">
						<!-- <el-checkbox v-model="checkAll" @change="changeAll"></el-checkbox> -->
						<!-- <img src="@/assets/cart/sel.png" alt=""/> -->
						<!-- <img src="@/assets/cart/no_sel.png" alt="" @click="changeSelectState('all', cartData.data.checkedAll)" /> -->
						<span style="margin-left: 18px;">图片</span>
					</div>
					<div class="cart_title_pre">
						<span>产品信息</span>
					</div>
					<div class="cart_title_pre">
						<span>描述</span>
					</div>
					<!-- <div class="cart_title_pre">
						<span>生产状态</span>
					</div> -->
					<div class="cart_title_pre">
						<span>价格</span>
					</div>
					<!-- <div class="cart_title_pre">
						<span>购买数量</span>
					</div> -->
				</div>
				<!-- 顶部标题 end-->

				<!-- 列表 start -->
				<div class="cart_lists">
					<div class="cart_lists_pre" v-for="(item, index) in frequentList" :key="index">
						<!-- <div class="invalid_goods_title">{{ L["失效产品"] }}</div> -->
						<div class="goods_list flex-row">

							<!-- 可选择 1-正常状态，2-商品失效（已删除），3-商品无货-->
							<div class="goods_pre_sel">
								<!-- <el-checkbox v-model="item.ischeck" @change="changeSelect(index, $event)"></el-checkbox> -->
								<!-- <img src="@/assets/cart/sel.png" alt="" class="store_sel"/> -->
								<!-- <img src="@/assets/cart/no_sel.png" alt="" class="store_sel"/> -->
								<!-- 不可选择 无货-->
								<!-- <img src="@/assets/cart/no_sel.png" alt="" class="store_sel"/> -->
							</div>

							<!-- 图片 -->
							<div class="section8" @click="productInfo(item.productId)">
								<div class="section8_img" :style="`background-image:url(${item.goodsImage});`">
									<div class="section88">
										<img :src="item.goodsImage" />
									</div>
								</div>
							</div>

							<!-- 规格型号 -->
							<div class="section8 flex-col">
								<span class="txt19">名称：{{ item.goodsName }}</span>
								<br>
								<span class="txt19" v-if="item.specValues">规格：{{ item.specValues }}</span>
								<br>
								<span class="txt19" v-if="item.orderCode">订货编码：{{ item.orderCode }}</span>
							</div>

							<!-- 描述 -->
							<div class="section8 txt19_box">
								<el-scrollbar>
									<div class="flex-col">
										<span class="txt19" v-for="attr in item.attributeValueList" :key="attr">
											{{ attr.attributeName }}：{{ attr.attributeValue }}
										</span>
										<span class="txt19" v-show="item.attributeValueList.length == 0"
											style="text-indent:26px;line-height:92px;">--</span>
									</div>
								</el-scrollbar>
							</div>

							<!-- 生产状态 -->
							<!-- <div class="section8 flex-col">
								<span class="txt20">{{ item.produceStateValue }}</span>
								<span class="info23">发货日：{{ item.deliverTime }}天</span>
							</div> -->

							<!-- 价格 -->
							<div class="section8 ">
								<div class="txt20 textPrice flex_row_center_center">
									<span v-if="item.goodsPrice > 0">￥{{ item.goodsPrice.toFixed(2) }}</span>
									<div v-else>--</div>
								</div>

							</div>

							<!-- 购买数量 -->
							<!-- <div class="section9 flex-col">
								<div class="goods_edit_nem flex_row_center_center">
									<span @click="editNum('reduce', item)">-</span>
									<input type="number" v-model="item.buyNum" @input="editNum('edit', item, $event)" />
									<span @click="editNum('add', item)">+</span>
									<span class="goods_edit_nem_after">{{ item.saleUnit }}</span>
								</div>
								<div class="section9_desc flex-row">
									<span>起订量 {{ item.minBuyNum }}</span>
									<span v-if="item.saleUnitDes">{{ item.saleUnitDes }}</span>
								</div>
							</div> -->
						</div>
					</div>
				</div>
				<!-- 列表 end -->
			</template>
			<SldCommonEmpty v-else totalWidth="1003" tip="暂无常购清单数据" />
			<el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
				:page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
				:hide-on-single-page="true" class="flex_row_end_center"></el-pagination>
		</div>

		<!-- 操作按钮 start -->
		<!-- <div v-if="frequentList.length > 0" class="options_btn flex_row_between_center" ref="optionsBtn">
			<div class="options_btn_left flex_row_start_center">
				<div class="options_sel">
					<el-checkbox v-model="checkAll" @change="changeAll"></el-checkbox>
					<span>{{ L["全选"] }}</span>
				</div>
			</div>
			<div class="options_right flex_row_start_center">
				<div class="options_sel_num">
					{{ L["已选择"] }}{{ total }}{{ L["件产品"] }}
				</div>
				<div class="go_buy cursor_pointer" :class="{ active: total > 0 }" @click="addCart">
					加入购物车
				</div>
			</div>
		</div> -->
		<!-- 操作按钮 end -->
	</div>
</template>

<script>
import { ref, getCurrentInstance, onMounted, reactive } from "vue";
import { useRouter } from 'vue-router';
import MemberTitle from '@/components/MemberTitle';
import SldCommonEmpty from '@/components/SldCommonEmpty'
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
export default {
	name: "regular-list",
	components: {
		MemberTitle,
		SldCommonEmpty
	},
	setup() {
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const router = useRouter();
		const store = useStore();
		const memberType = ref(store.state.memberInfo.memberType);
		const tabIndex = ref(1);
		const frequentList = ref([]);
		const confirmClick = ref(false); //加购事件防抖动
		const checkAll = ref(false);
		const total = ref(0);
		const cartList = reactive({ ids: ',', data: [] });
		const pageData = reactive({
			current: 1,
			pageSize: 10,
			total: 0
		});
		const selectValue = ref('');

		const changeTab = (index) => {
			//切换导航栏
			if (tabIndex.value != index) {
				tabIndex.value = index;
				pageData.current = 1;
				pageData.total = 0;
				total.value = 0;
				checkAll.value = false;
				cartList.ids = ',';
				cartList.data = [];
				getList();
			}
		};

		const searchList = () => {
			pageData.current = 1;
			pageData.total = 0;
			total.value = 0;
			checkAll.value = false;
			cartList.ids = ',';
			cartList.data = [];
			getList();
		}

		const getList = () => {
			let param = {
				time: tabIndex.value,
				current: pageData.current,
				pageSize: pageData.pageSize
			}
			if (selectValue.value) {
				param.keyword = selectValue.value;
			} else {
				delete param.keyword
			}

			proxy
				.$get('v3/business/front/orderInfo/frequentList', param)
				.then(res => {
					if (res.state == 200) {
						if (res.data.list.length > 0) {
							let num = 0;
							res.data.list.map(item => {
								let idList = cartList.ids.split(',')
								let index1 = idList.findIndex(i => i == item.productId)
								if (index1 != -1) {
									item.ischeck = true;
									num++;
								} else {
									item.ischeck = false;
								}
								item.buyNum = item.minBuyNum ? item.minBuyNum : 1;
							})
							if (num == res.data.list.length) {
								checkAll.value = true;
							}
						}
						frequentList.value = res.data.list;
						pageData.total = res.data.pagination.total;
						if (checkAll.value) {
							checkSelect(1);
						}
					} else {
						ElMessage.error(res.msg);
					}
				})
		};

		//页数改变
		const handleCurrentChange = e => {
			pageData.current = Math.floor(e);
			checkAll.value = false;
			getList();
		};

		//商品数量的编辑
		const editNum = (type, item, e) => {
			let miniNum = item.minBuyNum ? item.minBuyNum : 1;
			let maxNum = Math.min(10000, 99999)
			let currentSpecNum = item.buyNum;
			if (type == "add") {
				item.buyNum++;
				if (item.buyNum > maxNum) {
					item.buyNum = maxNum;
					ElMessage(L["数量超出购买范围！"]);
				}
			} else if (type == "edit") {
				currentSpecNum = e.currentTarget.value;
				if (currentSpecNum < miniNum) {
					item.buyNum = miniNum;
					ElMessage("最低限购" + miniNum + "件!");
				} else {
					item.buyNum = currentSpecNum.replace(/\D/g, "");
					if (currentSpecNum > maxNum) {
						item.buyNum = maxNum;
						ElMessage(L["数量超出购买范围！"]);
					}
				}

			} else if (type == "reduce") {
				if (currentSpecNum > miniNum) {
					item.buyNum--;
				} else {
					item.buyNum = miniNum;
					ElMessage("最低限购" + miniNum + "件!");
				}
			}

			let index = cartList.data.findIndex(i => i.productId == item.productId);
			index > -1 && (cartList.data[index].number = item.buyNum)

		};

		const changeAll = () => { //全选切换
			checkSelect(checkAll.value ? 1 : 2);
		}
		const changeSelect = () => { //商品选择切换
			checkSelect();
		}
		const checkSelect = (type) => { //筛选选中的商品 type: 1 全选 2 全不选
			let num = 0;
			let ids = cartList.ids;
			let idList = ids.split(',').filter(i => i)
			let arr = cartList.data;
			frequentList.value.map(item => {
				let index = idList.findIndex(i => i == item.productId);
				let list = {
					productId: item.productId,
					number: item.buyNum
				}

				if (type == 1) {
					item.ischeck = true;
					num++;
				} else if (type == 2) {
					item.ischeck = false;
				} else if (item.ischeck) {
					num++;
				}

				if (item.ischeck && index == -1) {
					idList.push(item.productId)
					arr.push(list);
				} else if (!item.ischeck && index != -1) {
					idList = idList.filter(i => i != item.productId)
					arr.splice(index, 1);
				}
			})

			console.log(arr, 'arr')
			if (!type) {
				checkAll.value = (num == frequentList.value.length ? true : false)
			}

			total.value = arr.length;
			cartList.ids = idList.join(',');
			cartList.data = arr;
		};

		const addCart = () => { //加入购物车
			if (confirmClick.value) {
				return;
			} else if (total.value == 0) {
				return;
			}
			confirmClick.value = true;
			let config = {
				header: 'Content-Type:application/json'
			}
			proxy
				.$post("v3/business/front/cart/batchAdd", cartList.data, config)
				.then((res) => {
					if (res.state == 200) {
						ElMessage.success(res.msg);
						checkAll.value = false;
						total.value = 0;
						cartList.ids = ',';
						cartList.data = [];
						// pageData.current = 1;
						// getList();
						checkSelect(2)
					} else {
						ElMessage.error(res.msg)
					}
				}).then(() => {
					confirmClick.value = false;
					proxy.$getLoginCartListData();
				})
		};

		const productInfo = (id) => {
			router.push({
				path: '/goods/detail',
				query: {
					productId: id
				}
			})
		};


		const resetList = () => {
			selectValue.value = ''
			getList()
		}

		onMounted(() => {
			getList();
		});

		return {
			L,
			pageData,
			tabIndex,
			frequentList,
			changeTab,
			checkAll,
			total,
			editNum,
			changeAll,
			changeSelect,
			checkSelect,
			addCart,
			cartList,
			handleCurrentChange,
			productInfo,
			selectValue,
			searchList,
			resetList,
			memberType,
			confirmClick
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../style/orderRegularList.scss";
</style>
<style lang="scss">
.sld_regular_list {
	.layer13 {
		.el-input--suffix {
			.el-input__inner {
				height: 38px;
				padding: 0 8px;
				border: none;
			}
		}
	}
}
</style>